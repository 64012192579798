import React from 'react';
import PropTypes from 'prop-types';
import { GradientLink } from './gradient-link.css';

const _GradientLink = ({ children, ...props }) => (
  <GradientLink {...props}>{children}</GradientLink>
);

_GradientLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default _GradientLink;
