import React from 'react';
import PropTypes from 'prop-types';
import { Banner } from './banner.css';

const _Banner = ({ children }) => <Banner>{children}</Banner>;

_Banner.propTypes = {
  children: PropTypes.node.isRequired,
};

export default _Banner;
